.horizontal-slider {
    height: 50px;
}

.thumb-slider {
    top: 1px;
}

.example-track {
    top: 20px;
    height: 5px;
}
